import {ProjectCarousel} from "./carousel";

var Filtered = null;

export const AjaxLoad =  (Url, Id, Container, Event) => {

        var AjaxContainer = document.getElementById(Container);
        AjaxContainer.classList.remove("is-loaded");
        var Body = document.body;
        Body.removeAttribute("id");
        Body.setAttribute("id", Id.replace("/",""));
        AjaxContainer.innerHTML = "";
        AjaxContainer.setAttribute("data-page", Id.replace("/",""));
        Body.classList.add('ajax-container-visible');
        fetch(Url).then(function (response) {
            return response.json();
        }).then(function (content) {
            AjaxContainer.scrollTop = 0;
            AjaxContainer.innerHTML = content;
            AjaxContainer.classList.add("is-loaded");
            history.pushState(null, null, Id);
            Init();
        });

}

export const Init = () => {

    console.log(Filtered);

    const Filters = document.querySelectorAll(".filters");
    const Containers = document.querySelectorAll(".filtered");

    Filters.forEach((filter) => {
        const targetId = filter.dataset.target;

        filter.addEventListener("click", function (e){
            e.preventDefault();
            const target = document.getElementById(targetId);
            Filtered = targetId;
            console.log(Filtered);
            Filters.forEach((filter) => {
                filter.classList.remove("active");
            });

            filter.classList.add("active");

            Containers.forEach((container) => {
                container.classList.add("hide");
                container.classList.remove("show");
            });

            target.classList.remove("hide");
            target.classList.add("show");

        });


    });

    const Projects = document.querySelectorAll(".projects-container .load-page");

    Projects.forEach((project) => {
        const Id = project.getAttribute("href");
        const Url = Id + ".json";

        project.addEventListener("click", function (e){
            e.preventDefault();
            AjaxLoad(Url, Id, "main");
        });
    });


    ProjectCarousel();

    if(Filtered){
        Filters.forEach((filter) => {
            filter.classList.remove("active");
        });

        Containers.forEach((container) => {
            container.classList.add("hide");
        });

        document.querySelector(".filters[data-target='"+Filtered+"']")?.classList.add("active");
        document.querySelector(".filters[data-target='"+Filtered+"']")?.classList.remove("hide");

        document.getElementById(Filtered)?.classList.remove("hide");
        document.getElementById(Filtered)?.classList.add("show");
    }




}
