import {ProjectCarousel} from "./_modules/carousel";

__webpack_public_path__ = "http://dev.local:8080/assets/";

import "../scss/application.scss";
import { onLoading } from "./_modules/website";
import { loadPage } from "./_modules/home";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import {Init} from "./_modules/ajaxload";


import {ProjectsMenu} from "./_modules/projectsMenu";
import {MainMenu} from "./_modules/projectsMenu";
import {AjaxLoad} from "./_modules/ajaxload";


document.addEventListener("DOMContentLoaded", () => {
  onLoading();
  loadPage();

  if( window.location.pathname == "/"){
    AjaxLoad("home.json", "home", "main");
  }else{
    AjaxLoad(window.location.pathname+".json", window.location.pathname, "main");
  }

  const menuItems = document.querySelectorAll(".load-page");

  menuItems.forEach((menuItem) => {

    menuItem.addEventListener("click", function (e){
      e.preventDefault();
      const Container = this.getAttribute('data-container');
      const Id = this.getAttribute("href");
      const Url = Id + ".json";
      AjaxLoad(Url, Id, "main");
    });
  });

  window.addEventListener('popstate', function(event) {

    var Id = window.location.pathname;
    var Url = Id + ".json";
    var Container = "main";
    AjaxLoad(Url, Id, Container);

  });


});